<template>
  <div class="exam-detail-page testPaper m-container" v-loading="loading">
    <div class="exam-detail clearfix" v-if="examType === 'canTest'">
      <div class="exam-left fl">
        <div class="exam-top">
          <div class="exam-top-left">
            <div class="exam-top-title">
              <h1 :title="examDetail.name">
                {{ examDetail.name | ellipsis }}
              </h1>
              <span>答题中</span>
            </div>
            <div class="bottom">
              <span>总分：{{ examDetail.totalScore }}分</span>
              <span>及格分：{{ examDetail.passingScore }}分</span>
            </div>
          </div>
          <p />
        </div>
        <div class="rightPhoneContent" v-if="controlSwitch.isOpenPhoneCamera == 1" @mouseenter="enterCode"
          @mouseleave="leaveCode" :style="{ 'z-index': phoneLiveStatus ? '' : 11 }">
          <div>
            <div class="topView">
              <div class="exam-top-rights" v-if="controlSwitch.isOpenPhoneCamera == 1">
                <div class="linev"></div>
                <div class="h5State" style="margin-top: 10px">
                  手机摄像头：
                  <span v-if="phoneLiveStatus" class="green-status">正常</span>
                  <span v-else> 异常 </span>
                </div>
                <div class="imageCode" style="margin-top: 10px">
                  <img class="cover" src="../asset/images/qrcode-s.png" alt="" id="coverImg">
                </div>
              </div>
            </div>
            <div class="temArrowI">
            </div>
            <div v-show="isShowH5Error" class="h5Code">
              <div class="arrowI">
                <div class="contentA"></div>
              </div>
              <span>手机摄像头监控</span>
              <!-- <div ref="qrCodeUrl"  class="h5ContC"></div> -->
              <img src="../asset/images/qrcode-s.png" class="h5ContC" id="qrcodeImg" />
              <dd>请使用微信扫一扫登录手机监控系统</dd>
            </div>
          </div>
        </div>

        <div class="scroll">
          <div v-if="paperType == 1 || paperType == 3 || paperType == 4">
            <div v-for="(item, index) in questionsList" :key="index" class="list">
              <div class="test-questions">
                <div v-if="item.questionList.length > 0" class="question-type">
                  <span class="line"></span>
                  <span class="h1">
                    {{
                      item.type == 1
                        ? '单选题'
                        : item.type == 2
                          ? '多选题'
                          : item.type == 3
                            ? '判断题'
                            : item.type == 4
                              ? '高阶题'
                              : item.type == 5
                                ? '问答题'
                                : item.type == 6
                                  ? '填空题'
                                  : item.type == 7
                                    ? '组合题'
                                    : ''
                    }}
                  </span>
                  <span class="small">
                    (共{{ item.questionList.length }}题，共{{ item.totalMark }}分)
                  </span>
                </div>
              </div>
              <div v-for="(question, index1) in item.questionList" :id="'s' + question.questionId"
                :key="question.questionId" class="questions">
                <ul class="cpStOption">
                  <div class="subject">
                    <div class="flex-title">
                      <span class="score">{{ question.score | grade }}分</span>
                      <h1 class="subject-title">
                        <span>{{ question.questionNo + 1 }}.</span>
                        <p v-html="question.title" class="questionstem">
                        </p>
                      </h1>
                    </div>
                    <div v-if="item.type != 7" class="signIcon" @click="markClick(question, index1, item.type)">
                      <span class="mark-btn" :class="{ active: question.mark }" />
                      <span v-if="!question.mark" class="text">标记</span>
                      <span v-else class="text">取消</span>
                    </div>
                  </div>
                  <div class="topicOptions">
                    <!-- 简答题 -->
                    <div v-if="question.type === 5">
                      <el-input type="textarea" :rows="7" placeholder="请输入答案…（限制2000字以内）" maxlength="2000"
                        show-word-limit v-model="question.myAnswers" @change="val => getop2(val, question)">
                      </el-input>
                      <!-- 上传 -->
                      <uploadZip v-if="question.uploadFlag === 1" :question="question" @enclosureData="enclosureData" />
                      <!-- 上传 end-->
                    </div>
                    <!-- 填空题 -->
                    <div v-if="item.type === 6" class="completionBox">
                      <div class="Completion-item" v-for="(completion, completionIndex) in question.completionAnswer"
                        :key="'comple' + completionIndex">
                        <span class="label">第{{ completionIndex + 1 }}空：</span>
                        <el-input class="CompletionAnswers" v-model="completion.answer" placeholder="请输入答案"
                          @change="(val) => getop3(val, question)"></el-input>
                      </div>
                    </div>
                    <!-- 组合题 -->
                    <div v-if="item.type === 7" class="combination">
                      <Combination :question="question" :subDtoList="question.subDtoList"
                        @handleClickOption="handleClickOption" @getop="getop" @getop2="getop2" @getop3="getop3"
                        @enclosureData="enclosureData" @forceUpdate="forceUpdate" />
                    </div>



                    <li v-for="(option, index2) in question.options" :key="index2" class="answer" @click="
                      handleClickOption(
                        question,
                        question.options,
                        item.type,
                        option,
                        index1,
                        index2
                      )
                      ">
                      <!-- 试题类题型(1:单选,2:多选,3:判断,4:高阶题) -->
                      <span v-if="item.type == 1 || item.type == 3 || item.type == 4 || item.type == 5"
                        class="option-btn" :class="{
                          active: option.isAnswers,
                        }" />
                      <!-- <span
                        v-if="item.type == 2"
                        class="el-checkbox__input"
                        :class="{
                          'is-checked': option.isAnswers,
                        }"
                      >
                        <span class="el-checkbox__inner"></span>
                      </span> -->
                      <el-checkbox @change="val => getop(val, question,
                        question.options
                      )" v-if="item.type == 2" v-model="question.options[index2].isAnswers">
                        <span class="option-text" :class="{
                          active: option.isAnswers,
                        }">
                          {{ option.optionNumber }}
                        </span>
                        <span v-html="option.optionContent" class="option-text-content"></span>
                      </el-checkbox>
                      <template v-if="item.type == 1 || item.type == 3 || item.type == 4">
                        <span class="option-text" :class="{
                          active: option.isAnswers,
                        }">
                          {{ option.optionNumber }}
                        </span>
                        <span v-html="option.optionContent" class="option-text-content"></span>
                      </template>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <fieldTest :parameter="parameter" v-if="operationExam" id="fieldTest"></fieldTest>
          <!-- <div class="call">
              <img v-if="callStatus === 0" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/call1.png" alt="" @click="saveCall()">
              <img v-else src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/call2.png" alt="" disabled>
          </div> -->
        </div>
      </div>
      <div ref="examRight" class="exam-right fr">
        <div class="answer-box">
          <div class="exam-top-right" v-if="secondsExam > 0">
            <div class="titles">
              <svg-icon icon-class="time"></svg-icon>
              剩余时间
            </div>
            <div class="time">{{ duration }}</div>
          </div>
          <div v-if="paperType == 1 || paperType == 3 || paperType == 4">
            <div :class="['titleList',
              secondsExam > 0 ? (controlSwitch.isAllotVideoMonitor == 1 ? 'superviseList' : 'noSupervis') :
                (controlSwitch.isAllotVideoMonitor == 1 ? 'noTime' : 'noTimeAndSupervis')]">
              <div class="scantron">
                <div class="titles">
                  <svg-icon icon-class="datika"></svg-icon>
                  答题卡
                </div>
                <div class="type">
                  <span class="finish"><span></span>已答</span>
                  <span class="none"><span></span>未答</span>
                </div>
              </div>
              <div ref="stCount" class="answer-num">
                <div v-for="(mark, markIdx) in questionsList" :key="mark.id" class="list">
                  <h1 v-if="mark.questionList.length > 0">
                    <span class="line" />
                    <span class="type">
                      {{
                        mark.type == 1
                          ? '单选题'
                          : mark.type == 2
                            ? '多选题'
                            : mark.type == 3
                              ? '判断题'
                              : mark.type == 4
                                ? '高阶题'
                                : mark.type == 5
                                  ? '问答题'
                                  : mark.type == 6
                                    ? '填空题'
                                    : mark.type == 7
                                      ? '组合题'
                                      : ''
                      }}
                    </span>
                    <div class="subQuestionBox" v-if="mark.type == 7">
                      <div v-for="(subQuestion, subIndex) in mark.questionList" :key="subIndex">
                        <div>
                          <span>组合题{{ subIndex + 1 }}</span>
                          <span class="score">（共{{ subQuestion.subDtoList.length }}题 合计{{ subQuestion.score }}分）</span>
                        </div>
                        <!-- 锚点组件 -->
                        <Anchor ref="anchor" :list="subQuestion.subDtoList" :question="subQuestion" />
                      </div>
                    </div>
                    <span v-else class="score">（共{{ mark.questionList.length }}题 合计{{ mark.totalMark }}分）</span>
                  </h1>
                  <template v-if="mark.type != 7">
                    <Anchor ref="anchor" :list="mark.questionList" :question="questionsList[markIdx]"
                      :combinationFlag="false" />
                  </template>
                </div>
              </div>
              <div class="answer-btn">
                <el-button type="primary" @click="submitExam">马上交卷</el-button>
              </div>
            </div>
          </div>
          <div v-if="paperType == 2">
            <div :class="['titleList',
              secondsExam > 0 ? (controlSwitch.isAllotVideoMonitor == 1 ? 'superviseList' : 'noSupervis') :
                (controlSwitch.isAllotVideoMonitor == 1 ? 'noTime' : 'noTimeAndSupervis')]">
              <div class="scantron">
                <div class="titles">试卷信息</div>
                <div class="type">
                  <p>共<span>{{ examDetail.totalNumQuestions }}</span>题，总计<span>{{ examDetail.totalScore }}</span>分</p>
                </div>
              </div>
              <div class="answer-table">
                <div class="table">
                  <div class="th">
                    <div>题号</div>
                    <div>分值<span>(分)</span></div>
                  </div>
                  <div class="td" v-for="(item, index) in examDetail.drillQuestions" :key="index">
                    <div>{{ index + 1 }}</div>
                    <div>{{ item.questionScore }}</div>
                  </div>
                </div>
              </div>
              <div class="answer-btn">
                <el-button type="primary" @click="submitExam">马上交卷</el-button>
              </div>
            </div>
          </div>
          <!-- 考试监控窗口 -->
          <!-- @connected="connected"  监听视频接通后的回调 -->
          <div style="width:264px;height:264px;background:black;margin-top:40px;" v-if="!isPushFlow"
            v-loading="isPushLoading">

          </div>
          <!-- <div v-else>
            <live-view
              @showShade="showPcShade = false"
              ref="live"
              v-if="controlSwitch.isAllotVideoMonitor == 1 && isPushFlow"
              :controlSwitch="controlSwitch"
              :whetherCapture="faceWarningVisible"
              :showDetectionBtn="showDetectionBtn"
              @pushFlow="pushFlow"
            />
          </div> -->
        </div>
      </div>
    </div>
    <div class="warningPage" v-if="examType === 'notTest'">
      <img src="../asset/images/examWarning.png" alt="">
    </div>
    <!-- 呼叫考官提示框 -->
    <el-dialog class="call-dialog" :visible.sync="callDialog" width="400px" :before-close="handleClose1">
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">提示</p>
      <p class="text">确认呼叫考官吗？</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="callSaveCancel" style="width: 100%">取消</el-button>
        <el-button type="primary" @click="callSaveConfirm" style="width: 100%">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog class="call-dialog" :visible.sync="callKnowDialog" width="400px" :before-close="handleClose2">
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">提示</p>
      <p class="text">已通知考官，注意接听电话！</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="callKnow" style="width: 100%">知道了</el-button>
      </span>
    </el-dialog>
    <!-- 5分钟1分钟的倒计时 -->
    <el-dialog class="tip-dialog" :visible.sync="coursedialogVisible" width="580px" :before-close="handleClose">
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">{{ text1 }}</p>
      <p class="text">{{ text2 }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" v-if="onlyCertain">{{
          cancelText
          }}</el-button>
        <el-button type="primary" :style="onlyCertain ? 'min-width:120px' : 'width:100%'"
          @click="confirmbtn(fiveTime ? 300 : oneTime ? 60 : null, distinguish)">{{ submitText }}</el-button>
      </span>
    </el-dialog>
    <!-- 系统自动交卷 -->
    <el-dialog class="tip-dialog" :visible.sync="voluntarilydialogVisible" width="400px"
      :before-close="voluntarilyHandleClose">
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">{{ text1 }}</p>
      <p class="text">{{ text2 }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="voluntarilyHandleClose" v-if="onlyCertain">{{
          cancelText
          }}</el-button>
        <el-button type="primary" :style="onlyCertain ? 'min-width:120px' : 'width:100%'"
          @click="voluntarilyConfirmbtn(distinguish)">{{ submitText }}</el-button>
      </span>
    </el-dialog>

    <el-dialog class="submit-dialog" :visible.sync="submitdialogVisible" width="400px" :before-close="handleClose">
      <p class="titles">提示</p>
      <p class="text">
        交卷前需进行人脸验证，是否要进行交卷? 点击交卷并进行人脸验证
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn1" @click="giveUup">放弃考试</el-button>
        <el-button class="btn2" @click="continues">继续答题</el-button>
        <el-button class="btn3" type="primary" @click="confirmbtnDiscern">我要交卷</el-button>
      </span>
    </el-dialog>
    <el-dialog class="warning-dialog" :visible.sync="warningdialogVisible" width="400px" :before-close="handleClose">
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">警告</p>
      <div v-if="switchScreenNum > 0">
        <p class="text">检测到切屏{{ userSwitchNum }}次，请勿切换页面，遵守考试纪律！</p>
        <!-- <p class="text">你试图离开考试页面，警告第<span class="red">{{ num }}</span>次</p>
        <p class="text">(超过{{ switchScreenNum }}次机会，系统将强制提交试卷!)</p> -->
      </div>
      <!-- <div v-if="num >= switchScreenNum">
        <p class="text">检测到您已累计<span class="red">{{ switchScreenNum }}</span>次试图离开考试页面</p>
        <p class="text">系统将强制提交试卷！</p>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="konw(num)" style="width: 100%">我知道了</el-button>
      </span>
    </el-dialog>
    <el-dialog class="fullScreen-dialog" :visible.sync="fullScreendialogVisible" width="400px"
      :before-close="handleClose">
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">提示</p>
      <p class="text">进入考场后，考生将进入全屏答题模式！禁止退出全屏</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="fullScreenTest" style="width: 100%">知道了</el-button>
      </span>
    </el-dialog>
    <el-dialog class="timeLimit-dialog" :visible.sync="timeLimitdialogVisible" :close-on-click-modal="false"
      width="400px">
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">提示</p>
      <!-- allowCommitDurationExam -->
      <p class="text">本次考试限制提前交卷，请在<span class="red">{{ timeRemain }}</span>分钟后再尝试提交试卷
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="timeLimitdialogVisible = false" style="width: 100%">知道了（{{ allowtime
          }}S后关闭）</el-button>
      </span>
    </el-dialog>
    <!-- 音频 -->
    <div id="examTaking"></div>
    <facedialog v-if="verification" :address="verificationAddress" :failAddress="verificationFailAddress"
      :pauseAddress="verificatioPpauseAddress" :invalidAddress="verificatioInvalidAddress" :type="examFaceType"
      :confirmdialog="confirmdialog" :commitExam="commitExam" :userPaperId="userPaperId"></facedialog>

    <!-- 人脸识别异常提醒 -->
    <warningDialog :warningVisible="faceWarningVisible" @close="handClose" @submit="handleOverFace"
      :warningData="loopErrData" ref="warn" />
    <!-- pc/h5端推流异常遮罩 -->
    <maskLayer :showPcShade="showPcShade" :phoneLiveStatus="phoneLiveStatus" :showDetectionBtn="showDetectionBtn" />
  </div>

</template>

<script>
import Vue from 'vue';
// import liveView from './liveView.vue';
import $ from 'jquery';
import resetMessage from '../../../libs/resetMessage';
import warningDialog from './dialogs/warningDialog.vue'; // 人脸异常警告
import maskLayer from './dialogs/maskLayer.vue'; // pc端异常遮罩
// import QRCode from "qrcodejs2";
import fieldTest from './fieldTest.vue';
import axios from '../../../libs/heartAxios'
import uploadZip from "../components/upload-zip";
import Combination from "./../components/combination.vue"; // 组合题
import Anchor from "./../components/Anchor"; // 锚点

export default {
  name: 'testPaper',
  show: true,
  components: { uploadZip, warningDialog, maskLayer, fieldTest, Combination, Anchor },
  data() {
    return {
      confirmdialog: {
        show: true,
      },
      currentQuestion: {},
      textarea: '',
      examType: '',
      loading: false,
      checked: false,
      text1: '',
      text2: '',
      cancelText: '',
      submitText: '',
      examDetail: {},
      maxtime: 0,
      stList: [], // 题号
      coursedialogVisible: false,
      voluntarilydialogVisible: false,//系统自动交卷
      warningdialogVisible: false,
      submitdialogVisible: false,
      faceWarningVisible: false, // 人脸识别弹窗
      showPcShade: false, // 显示pc/h5摄像头异常遮罩
      fullScreendialogVisible: false,
      timeLimitdialogVisible: false,
      questionsList: [], // 试题列表
      saveData: {
        userPaperId: '', // 试卷id
        commitTime: '', // 提交时间
        answerList: [], // 本试卷答题信息列表
        invalidType: null
      },
      duration: '',
      onlyCertain: true,// 只显示一个按钮
      fiveTime: null,
      oneTime: null,
      clickFlagFive: true,
      clickFlagOne: true,
      remainingTime: '',// 缓存剩余时间
      submissionTime: '',// 缓存进入考试时时间
      cacheQuestionsList: [],// 缓存试卷
      frameTime: '',// 弹框倒计时
      userPaperId: this.$route.query.userPaperId,
      businessId: this.$route.query.businessId,
      examId: this.$route.query.examId,
      winH: window.screen.height,
      newVal: null,
      newItem: null,
      // 开始答题时间戳
      startAnswerTime: null,
      // 点击交卷时间戳
      clickSubmitTime: null,
      //taskType为1，则为从培训任务跳过来的
      taskType: this.$route.query.taskType || null,
      // 考中人脸识别
      isFaceRecogDuringExam: JSON.parse(sessionStorage.getItem('isFaceRecogDuringExam')),
      // 考后人脸识别
      isFaceRecogCommitExam: JSON.parse(sessionStorage.getItem('isFaceRecogCommitExam')),
      // 防切屏次数  -1 不监控切屏
      switchScreenNum: 0,
      // 入口
      source: this.$route.query.source,
      // 考试允许用时 秒
      secondsExam: JSON.parse(sessionStorage.getItem('secondsExam')),
      // 允许交卷时长(单位:分;0:无,默认值0)
      allowCommitDurationExam: JSON.parse(sessionStorage.getItem('allowCommitDurationExam')),
      num: 0,
      verification: false,
      // 人脸识别成功  不交卷
      result: null,
      // 人脸识别成功  交卷
      commitSuccess: null,
      // 人脸识别成功跳转路由
      verificationAddress: {},
      // 人脸识别失败跳转路由
      verificationFailAddress: {},
      // 人脸识别取消跳转路由
      verificatioPpauseAddress: {},
      // 人脸识别超时跳转路由
      verificatioInvalidAddress: {},
      // 答题数量过半与时间过半人脸识别标识，只识别一次
      flag: true,
      // 监听已答题目过半时进行人脸验证
      changeNum: null,
      // 是否进行交卷
      submitOrNot: false,
      // 缓存当前时间（用于答题数量过半时人脸识别成功后用）
      verificationTime: null,
      // 人脸识别失败交卷监听
      failSubmit: false,
      // 页面失焦时间
      blurBefore: null,
      // 页面获取焦点事件
      blurAfter: null,
      // 人脸识别弹框传值是否要提交试卷
      commitExam: '',
      // 是否需要人脸识别  2否  1是
      distinguish: 2,
      // 考试时长
      halfTime: null,
      returnPath: {},
      webpageFlag: true,
      clientHeight: null,
      // true 非全屏
      nonFullScreen: false,
      getExamList: true,
      offlineCommit: false,
      commitType: '',
      commitStatus: '',
      polling: null, // 直播错误轮询
      loopErrData: {}, // 轮询结果
      controlSwitch: {}, // 控制是否显示直播 是否检测异常
      timeUsed: 0,// 考试已用时/min
      timeRemain: 0,// 剩余交卷时间/min
      isShowH5Error: false, //是否显示h5异常二维码
      phoneLiveStatus: true, // h5推流状态是否异常
      urlH5Str: '', // 异常二维码地址
      showDetectionBtn: false, // 是否显示设备检测按钮
      submitFlag: true, // 点击交卷标识
      paperType: null, // 考试类型
      parameter: {}, // 蓝桥参数
      loseFocus: false, // 是否失去焦点
      operationExam: false, // 是否为实操考试
      heartbeatTimer: null, //记录在线人数定时器
      callDialog: false,//呼叫考官提示弹窗
      callKnowDialog: false,//呼叫考官  已通知考官，注意接听电话！提示弹窗
      callStatus: 0,// 呼叫  状态  0呼叫  1已通知
      callTimer: null,// 记录呼叫状态定时器
      resizeTimer: null,
      isPushFlow: true,
      isPushLoading: true,
      examFaceType: 1, // 考前
      userSwitchNum: 0,
      allowtime: 5,
      allowtimeInterval: null,
      timerCommitPaper: null, // 30秒调用一次commitPaperByTimes

    };
  },
  filters: {
    ellipsis(msg) {
      if (msg && msg.length > 34) {
        return msg.substr(0, 34) + '...'
      }
      return msg
    },
    grade(msg) {
      if (msg % 1 === 0) {
        return msg + '.0'
      } else {
        return msg.toFixed(1)
      }
    },
  },
  watch: {
    // 监听已答题目过半时进行人脸验证
    'changeNum'(val) {
      if (val >= Math.ceil(Number(this.stList) / 2) && this.flag && this.isFaceRecogDuringExam == 1) {
        this.verification = true;
        this.confirmdialog.show = true;
        this.examFaceType = 2
        this.verificationAddress = {};
        this.verificationFailAddress = {};
        this.verificatioPpauseAddress = {};
        this.verificatioInvalidAddress = this.returnPath;
      }
    },
    // 关闭人脸识别弹窗，继续倒计时
    'verification'(val) {
      if (val == false && this.secondsExam > 0) {
        console.log(val)
      }
    },
    // 人脸识别成功
    'result'(val) {
      if (val) {
        this.flag = false;
      }
    },
    // 点击交卷后人脸识别成功
    'commitSuccess'(val) {
      console.log(val, '=====点击交卷后人脸识别成功====')
      if (val) {
        if (this.submitOrNot) {
          this.commitAllPaper();
        }
        this.flag = false;
      } else {
        if (this.submitOrNot) {
          this.commitAllPaper(1);
        }
      }
    },
    // 人脸识别失败
    'failSubmit'(val) {
      if (val) {
        this.commitAllPaper(1, 'fail');
      }
    }
  },
  mounted() {
    if (localStorage.getItem('faceWarningVisible') === 'true') {
      this.faceWarningVisible = true
      this.loopErrData = JSON.parse(localStorage.getItem('loopErrData'))
    }
    window.addEventListener('beforeunload', () => {
      this.cacheTestPaper();
    });
    window.addEventListener('setItem', this.storage);
    window.addEventListener("online", this.haveNet);
    this.sendHeartbeat();//10秒发送一次,记录在线人数
    this.callHeartStatus();//10秒发送一次,查询呼叫状态
    if (this.source == 'examDetails') {
      this.returnPath = {
        path: '/examDetails',
        query: {
          bussinessId: this.businessId,
          examId: this.examId,
          taskType: this.taskType,
          dymode: this.$route.query.dymode,
          title: sessionStorage.getItem("examDetailsTitle"),
          timeSlot: sessionStorage.getItem("examDetailsTimeSlot"),
          startTime: sessionStorage.getItem("examDetailStartTime"),
          endTime: sessionStorage.getItem("examDetailendTime")
        }
      }
    }
  },
  async created() {
    // this.returnButton();
    await this.examDetailData(); // 获取页面是否显示直播窗口
    this.cacheQuestionsList = JSON.parse(sessionStorage.getItem("questionsList")) || []
    this.submissionTime = JSON.parse(sessionStorage.getItem("submissionTime"))
    sessionStorage.removeItem("questionsList");
    if (sessionStorage.getItem('secondsExam') && sessionStorage.getItem('allowCommitDurationExam')) {
      console.log('可考')
      this.examType = 'canTest';
      // 30秒调用一次commitPaperByTimes
      this.timerCommitPaper = setInterval(() => {
        this.commitPaperByTimes();
      }, 10000/2);





      this.loading = true;
      console.log('+this.$route.isMonitor === 0---->', this.$route.query.isMonitor)
      if (this.$route.query.isMonitor == 0) {
        this.fullScreendialogVisible = true;
      } else {
        this.fullScreenTest()
      }

    } else {
      console.log('不可考')
      this.examType = 'notTest';
    }

  },
  beforeDestroy() {
    this.exitFullscreen();
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer)
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.polling) {
      clearInterval(this.polling);
      if (this.$refs.live) {
        this.$refs.live.stopLiving();
      }
    }
    if (this.callTimer) {
      clearInterval(this.callTimer);
    }
    if (this.allowtimeInterval) {
      clearInterval(this.allowtimeInterval);
    }
    if (this.$refs.live) {
      this.$refs.live.stopLiving();
    }
  },
  destroyed() {
    window.removeEventListener('setItem', this.storage);
    $(window).unbind("blur resize");
    $(window).unbind("focus resize");
    window.removeEventListener('focus resize', this.recalculateTime);
    window.removeEventListener("online", this.haveNet);
    // window.removeEventListener('visibilitychange', this.checkFull);
    window.removeEventListener('beforeunload', this.cacheTestPaper);
    document.body.oncontextmenu = document.body.ondragstart = document.body.onselectstart = document.body.onbeforecopy = function () { return true; };
    clearInterval(this.timer);
    clearInterval(this.warning);
    clearInterval(this.knowTime);
    clearInterval(this.polling);
    clearInterval(this.timerCommitPaper);
    if (this.$refs.live) {
      this.$refs.live.stopLiving();
    }
    localStorage.removeItem('uplinkNetworkQuality');
    localStorage.removeItem('LiveParam');
    sessionStorage.removeItem("submissionTime");
    sessionStorage.removeItem("secondsExam");
    sessionStorage.removeItem("allowCommitDurationExam");
  },
  beforeRouteUpdate(to) {
    if (to.path === '/exam/testPaper') {
      this.cacheQuestionsList = JSON.parse(sessionStorage.getItem("questionsList"))
      return;
    }
  },
  beforeRouteLeave(to, from, next) {  // 路由跳转前，清除轮询
    next();
    if (this.polling) {
      clearInterval(this.polling);
      this.polling = null;
    }
    localStorage.removeItem('LiveParam');
    localStorage.removeItem('uplinkNetworkQuality');
  },
  methods: {
    getQuetion(question) {
      this.currentQuestion = question
    },

    //附件信息
    enclosureData(datas, question) {
      if (datas.length) {
        question.uri = datas.length ? JSON.stringify(datas) : ''
        if (question.uri && question.uri.length > 0) {
          question.result = true
        } else {
          question.result = false
        }
      } else {
        // 有文本输入 表示为答题
        if (question.myAnswers) {
          question.result = true
        } else question.result = false
      }
      console.log();
    },
    pushFlow(val) {
      this.isPushFlow = val
      setTimeout(() => {
        this.isPushFlow = true
      }, 2000)
    },
    handClose(val) {
      this.faceWarningVisible = false;
      // 管理员发送警告
      if (val === 6) {
        if (this.$refs.live) {
          this.$refs.live.getLocalStreamVideoFrame();
        }
      }
      localStorage.removeItem('faceWarningVisible');
      localStorage.removeItem('loopErrData');
    },
    // 呼叫考官动作
    saveCall() {
      this.callDialog = true
    },
    // 呼叫考官动作    弹窗确认
    callSaveConfirm() {
      const data = {
        examId: this.$route.query.examId,
        userPaperId: this.$route.query.userPaperId
      }
      this.$api.exam.saveCall(data).then(res => {
        if (res.success) {
          this.callDialog = false
          const params = {
            examId: this.$route.query.examId,
            userPaperId: this.$route.query.userPaperId
          }
          this.$api.exam.findByStatus(params).then(res => {
            if (res.success) {
              this.callStatus = res.data
            }
          })
          this.callKnowDialog = true

        }
      })
    },
    // 呼叫考官动作    弹窗取消
    callSaveCancel() {
      this.callDialog = false
    },
    //呼叫考官  已通知考官，注意接听电话！提示弹窗
    callKnow() {
      this.callKnowDialog = false
    },
    // 查询呼叫状态   轮训
    callHeartStatus() {
      const params = {
        examId: this.$route.query.examId,
        userPaperId: this.$route.query.userPaperId
      }
      this.$api.exam.findByStatus(params).then(res => {
        if (res.success) {
          this.callStatus = res.data
        }
      })
      this.callTimer = setInterval(() => {
        this.$api.exam.findByStatus(params).then(res => {
          if (res.success) {
            this.callStatus = res.data
          }
        })
      }, 10000);
    },
    sendHeartbeat() {
      let option = {
        url: "/standard-saas/v1/data/sendHeartbeat",
        params: { examId: this.examId }
      };
      axios(option)

      this.heartbeatTimer = setInterval(() => {
        let option = {
          url: "/standard-saas/v1/data/sendHeartbeat",
          params: { examId: this.examId }
        };
        axios(option)
      }, 10000);
    },
    haveNet() {
      if (this.offlineCommit == true) {
        this.commitAllPaper(this.commitType, this.commitStatus);
      }
    },
    cacheTestPaper() {
      sessionStorage.setItem("questionsList", JSON.stringify(this.questionsList));
    },
    // 生成二维码链接
    async createCodeH5URL(error) {
      let localDomain = JSON.parse(window.localStorage.getItem('domain')).filter(item => item.frontend_template_id == '4')
      let paramURL = 'https://' + localDomain[0].domain + '/device_check?'
      // let paramURL = 'https://hrss-stu.testing2.wdeduc.com' + '/before_live?'
      let qureyS =
        this.examId + '&='
        + this.$route.query.taskType + '&='
        + this.businessId + '&='
        + localStorage.getItem('token')
        + '&='
        + this.$route.query.userPaperId
      let params =
      {
        'token': qureyS
      }
      let res = await this.$api.exam.saveTKData(params)



      // 如果报错误异常二维码只生成一次
      if (error && this.urlH5Str) return

      let urlH5Str = paramURL + 'tokens=' + res.data
      this.urlH5Str = urlH5Str


      // if(this.$refs.qrCodeUrl.title)return
      // this.creatQrCode(urlH5Str)

      this.getWxCode(res.data);
    },
    //   生成二维码
    // creatQrCode(urlH5Str) {
    //   if(this.$refs.qrCodeUrl.title)return
    //   new QRCode(this.$refs.qrCodeUrl, {
    //     text: urlH5Str, // 需要转换为二维码的内容
    //     width: 120,
    //     height: 120,
    //     colorDark: "#000000",
    //     colorLight: "#ffffff",
    //     correctLevel: QRCode.CorrectLevel.H,
    //   });
    // },
    // 获取小程序码
    getWxCode(tokens) {
      this.$api.exam.getWxCode(tokens).then((res) => {
        let a = 'data:image/png;base64,' + res.data.image;
        console.log(a, '1')
        document.getElementById('qrcodeImg').src = a;
        document.getElementById('coverImg').src = a;

      })
    },
    enterCode() {
      this.isShowH5Error = true;
      this.$nextTick(() => {
        this.createCodeH5URL(false);
      })
    },
    leaveCode() {
      this.isShowH5Error = false;
      // 异常二维码地址
      this.urlH5Str = ''
    },
    fullScreenTest() {
      this.fullScreendialogVisible = false;
      this.nonFullScreen = false;
      if (+this.$route.query.isMonitor === 0) {
        setTimeout(() => {
          this.full();
        }, 500)
      }
      console.log('走到这里了')
      if (this.getExamList) {

        this.paperType = this.controlSwitch.paperType;
        console.log('走到这里了1', this.getExamList, this.paperType)
        if (this.paperType == 1 || this.paperType == 3 || this.paperType == 4) {
          this.findQuestForExam();
          console.log('走到这里了2this.paperType', this.getExamList)
        } else if (this.paperType == 2) {
          this.findQuestAndLabidForExam();
        }
        this.getExamList = false;
        if (this.controlSwitch.isAllotVideoMonitor == 1 && this.num == 0) {
          this.getDateLoop(); // 开始轮询
        }
        // else {
        //   this.findQuestForExam();
        //   this.getExamList = false;
        // }
      } else {
        this.recalculateTime();
      }
    },
    // // 视频监考设备已接通
    // connected () {
    //   this.fullScreendialogVisible = true;
    // },
    // 放弃考试，直接交卷，成绩异常
    giveUup() {
      clearInterval(this.warning);
      this.submitdialogVisible = false;
      this.commitAllPaper(1);
    },
    // 继续答题
    continues() {
      this.submitdialogVisible = false;
    },
    // 全屏
    full() {
      const htmlEle = document.documentElement;
      if (htmlEle.requestFullscreen) {
        htmlEle.requestFullscreen();
      } else if (htmlEle.mozRequestFullScreen) {
        htmlEle.mozRequestFullScreen();
      } else if (htmlEle.webkitRequestFullscreen) {
        htmlEle.webkitRequestFullscreen();
      } else if (htmlEle.msRequestFullscreen) {
        htmlEle.msRequestFullscreen();
      }
      const that = this;
      window.onresize = function () {
        if (that.resizeTimer) {
          clearTimeout(that.resizeTimer)
        }
        that.$nextTick(() => {
          that.resizeTimer = setTimeout(() => {
            if (that.webpageFlag) {
              that.clientHeight = document.body.clientHeight;
              that.webpageFlag = false;
            }
            var sh = document.body.clientHeight;
            if (that.clientHeight != sh) {//退出全屏
              that.nonFullScreen = true;
              // 显示切屏此时
              // if (that.switchScreenNum > 0 && that.num<that.switchScreenNum) {
              // 不显示切屏次数
              if (that.switchScreenNum > 0) {
                that.userSwitchScreen();
              } else if (that.switchScreenNum <= 0) {
                that.fullScreendialogVisible = true;
              }
            } else {

              that.nonFullScreen = false;
            }
          }, 500)
        })
      }
    },
    // 退出全屏
    exitFullscreen() {
      if (document.exitFullScreen) {
        document.exitFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    //窗口失焦、大小变化监听
    blurResize() {
      const that = this;
      that.num = 0;
      // 失去焦点
      $(window).on('blur resize', function () {
        if (document.activeElement.tagName !== 'IFRAME') {
          this.blurBefore = Date.now();
          that.loseFocus = true;
        } else {
          that.loseFocus = false;
        }
      });
      // 获取焦点
      $(window).on('focus resize', function () {
        if (that.loseFocus) {
          this.blurAfter = Date.now();
          if (that.verificationTime > 0) {
            var time = parseInt((that.submissionTime - this.blurAfter) / 1000) + 1;
            clearInterval(that.timer);
            that.countDown(time);
          }
          if (this.blurAfter - this.blurBefore >= 10000) {
            setTimeout(() => {
              that.userSwitchScreen();
            }, 2000)
          }
        }
      });
    },
    recalculateTime() {
      const that = this;
      $(window).on('focus resize', function () {
        this.blurAfter = Date.now();
        if (that.verificationTime > 0) {
          var time = parseInt((that.submissionTime - this.blurAfter) / 1000) + 1;
          clearInterval(that.timer);
          that.countDown(time);
        }
      })
    },
    userSwitchScreen() {

      this.$api.exam
        .userSwitchScreen(this.$route.query.userPaperId)
        .then(res => {
          console.log('res---->1111', res)
          if (res.success) {
            this.num = res.data;
            this.userSwitchNum = res.data
            // 记录切屏次数
            // if (this.num<=this.switchScreenNum) {
            // 不记录切屏次数
            console.log('this.switchScreenNum----->2222', this.switchScreenNum, +this.$route.query.isMonitor == 0)
            if (this.switchScreenNum > 0 && +this.$route.query.isMonitor == 0) {
              this.warningdialogVisible = true;
            } else {
              // this.num = this.switchScreenNum;
            }
          }
        })
    },
    // 获取实操考试基本信息
    findQuestAndLabidForExam() {
      this.$api.exam
        .findQuestAndLabidForExam(this.$route.query.userPaperId)
        .then(res => {
          if (res.message == 'success') {
            this.loading = res.data ? false : true;
          }
          this.examDetail = res.data;
          this.switchScreenNum = this.examDetail.switchScreenNum;
          if (this.switchScreenNum > 0) {
            //屏蔽右击
            document.body.oncontextmenu = document.body.ondragstart = document.body.onselectstart = document.body.onbeforecopy = function () { return false; };
            this.blurResize();
            // window.addEventListener("visibilitychange", this.checkFull);
          } else {
            this.recalculateTime();
          }
          if (this.secondsExam > 0) {
            this.maxtime = this.secondsExam;
            if (!this.submissionTime) {
              this.submissionTime = new Date().getTime() + this.maxtime * 1000;
              sessionStorage.setItem("submissionTime", this.submissionTime);
            }
            var countDown = this.submissionTime ? (parseInt((this.submissionTime - Date.now()) / 1000)) : this.maxtime;
            this.halfTime = Math.ceil(countDown / 2)
            this.countDown(countDown);
          } else {
            this.startAnswerTime = new Date().getTime();
          }
          this.parameter = {
            courseId: this.examDetail.drillCourseId,
            labId: this.examDetail.drillLabId,
            userPaperId: this.userPaperId
          }
          this.operationExam = true;
        })
    },
    // 切屏次数用完时交卷
    konw(num) {
      this.warningdialogVisible = false;
      // 记录切屏次数
      // if (this.nonFullScreen && (this.switchScreenNum > 0)) {
      // 不记录切屏次数
      if (this.switchScreenNum > 0) {
        setTimeout(() => {
          this.full();
        }, 500)
      }
      if (num >= this.switchScreenNum) {
        // 去掉自动交卷
        // this.commitAllPaper(2);
        // if(this.$refs.live){
        //   this.$refs.live.stopLiving();
        // }
      }
    },
    // 点击退出登录时交卷
    storage() {
      this.newItem = sessionStorage.getItem('signOutStorage')
      if (this.newItem == 'bullet') {
        this.submitExam();
      }
    },
    // 浏览器回退按钮到训练营
    returnButton() {
      if (this.$route.name == 'testPaper') {
        let that = this;
        if (window.history && window.history.pushState) {
          window.onpopstate = function () {
            window.history.pushState('forward', null, '#');
            window.history.forward(1);
            that.submitExam();
          }
        }
        window.history.pushState('forward', null, '#'); //在IE中必须得有这两行
        window.history.forward(1);
      }
    },
    // 返回
    goBack() {
      this.submitExam();
    },
    // 交卷
    confirmbtn(times, num) {
      if ((times && this.clickFlagFive) || (times && this.clickFlagOne)) {
        this.coursedialogVisible = false;
        if (times == 300) {
          this.clickFlagFive = false;
        } else if (times == 60) {
          this.clickFlagOne = false;
        }
      }
      else {
        this.commitExam = 'exam';
        this.submitOrNot = true;
        if (num == 1) {
          this.coursedialogVisible = false;
          this.submitdialogVisible = true;
        } else {
          this.uploadTestPaper();
        }
      }

    },
    // 系统自动交卷【考试-PC】【偶现】考试5分钟和1分钟弹窗点击我知道了概率性会自动交卷
    voluntarilyConfirmbtn(num) {
      this.commitExam = 'exam';
      this.submitOrNot = true;
      if (num == 1) {
        this.voluntarilydialogVisible = false;
        this.submitdialogVisible = true;
      } else {
        this.uploadTestPaper();
      }
    },
    // 需要人脸识别的交卷
    confirmbtnDiscern() {
      this.commitExam = 'exam';
      this.submitOrNot = true;
      this.submitdialogVisible = false;
      this.examFaceType = 3
      this.uploadTestPaper();
      if (this.$refs.live) {
        this.$refs.live.stopLiving();
      }
    },
    // 关闭弹框
    handleClose() {
      Vue.prototype.resetSetItem('signOutStorage', 'login')
      this.coursedialogVisible = false;
      // 取消点击退出
      sessionStorage.setItem('expect', 'no')
    },
    // 系统自动交卷，关闭弹窗
    voluntarilyHandleClose() {
      Vue.prototype.resetSetItem('signOutStorage', 'login')
      this.voluntarilydialogVisible = false;
      // 取消点击退出
      sessionStorage.setItem('expect', 'no')
    },
    // 呼叫考官提示 关闭弹窗
    handleClose1() {
      this.callDialog = false
    },
    // 呼叫考官动作完成告知 关闭弹窗
    handleClose2() {
      this.callKnowDialog = false
    },
    // 点击标记
    markClick(question) {
      const question1 = question;
      this.$nextTick(() => {
        question.mark = !question1.mark;
        question.markFlag = !question1.markFlag;
        // 处理数据不更新问题
        this.forceUpdate()
      })
      console.log('question--mark--->', question)
    },
    forceUpdate() {
      this.$refs.anchor.forEach(ref => ref.forceUpdate());
    },
    getop2(val, question) {
      if (val.length > 0) {
        console.log('val, question------>', val, question)
        question.myAnswers = val
        question.result = true
        this.changeNum += 1;
      } else {
        question.result = false
        this.changeNum -= 1;
      }
    },
    getop3(val, question) {
      console.log('val, question------>', val, question, this.questionsList)
      let num = 0;
      question.completionAnswer.forEach(item => {
        if (item.answer) num++;
      })
      console.log(num, 'num===')
      if (num) {
        question.result = true;
        this.changeNum += 1;
      } else {
        question.result = false;
        this.changeNum -= 1;
      }
    },
    getop(val, question, optionList) {
      let myanswer = [];
      optionList.forEach(item2 => {
        console.log(3234234, item2);
        if (item2.isAnswers) {
          myanswer.push(item2.optionNumber);
          this.$set(item2, "isAnswers", true);
        } else {
          if (myanswer.length > 1 && myanswer.indexOf(item2.optionNumber) !== -1) {
            let i = myanswer.indexOf(item2.optionNumber);
            myanswer.splice(i, 1);
          }
          this.$set(item2, "isAnswers", false);
        }
      });
      this.$set(question, "myAnswers", myanswer.join(","));
      if (myanswer && myanswer.length > 0) {
        question.result = true;
        this.changeNum += 1;
      } else {
        question.result = false;
        this.changeNum -= 1;
      }
    },
    /* eslint-disable */
    // 选择
    handleClickOption(question, optionList, type, item, index, index2) {
      console.log(question, optionList, type, item, index, index2, 'handleClickOption');
      const item1 = item;
      const myanswer = []; // 多选题答案
      /* 试题类题型(1:单选,2:多选,3:判断,4:高阶题)*/
      if (type === 1 || type === 3 || type === 4) {
        optionList.forEach((item2, index3) => {
          const item4 = item2;
          if (index2 === index3) {
            this.$set(item1, "isAnswers", true);
            this.$set(question, "myAnswers", item1.optionNumber);
          } else {
            this.$set(item4, "isAnswers", false);
          }
        });
        question.result = true;
        this.changeNum += 1;
      }
      // else {
      // item1.isAnswers = !item1.isAnswers;
      // console.log(question.options[index2])
      // optionList.forEach(item2 => {
      //     if (item2.option) {
      //         myanswer.push(item2.optionNumber);
      //     }
      // });
      // console.log(myanswer)
      // this.$set(question, "myAnswers", myanswer.join(","));
      // if (myanswer.length > 0) {
      //     question.result = true;
      // } else {
      //     question.result = false;
      // }
      // }
      // 获取已答题数目
      // this.$nextTick(()=>{
      //     this.changeNum = document.getElementsByClassName('pointer ydt').length;
      // })
    },
    // 试题列表
    findQuestForExam() {
      let that = this;
      this.$api.exam
        .findQuestForExam(that.userPaperId, this.examId)
        .then(list => {
          if (list.message == 'success') {
            that.loading = list.data ? false : true;
          }
          that.examDetail = list.data;
          that.switchScreenNum = that.examDetail.switchScreenNum;
          if (that.switchScreenNum > 0) {
            //屏蔽右击
            document.body.oncontextmenu = document.body.ondragstart = document.body.onselectstart = document.body.onbeforecopy = function () { return false; };
            this.blurResize();
            // window.addEventListener("visibilitychange", this.checkFull);
          } else {
            this.recalculateTime();
          }
          if (that.secondsExam > 0) {
            that.maxtime = that.secondsExam;
            if (!this.submissionTime) {
              this.submissionTime = new Date().getTime() + this.maxtime * 1000;
              sessionStorage.setItem("submissionTime", this.submissionTime);
            }
            var countDown = that.submissionTime ? (parseInt((that.submissionTime - Date.now()) / 1000)) : that.maxtime;
            this.halfTime = Math.ceil(countDown / 2)
            that.countDown(countDown);
          } else {
            this.startAnswerTime = new Date().getTime();
          }
          var subjectList = that.examDetail.questions;
          console.log('cacheQuestionsList', this.cacheQuestionsList);
          console.log('sessionStorage', this.questionsList);
          if (that.cacheQuestionsList.length === 0) {
            that.questionsList = [];
            subjectList.forEach((item, index) => {
              console.log(item, 'item=====', index);
              item.questionNo = index // 根据下标 定为题号
              if (that.questionsList.length == 0) {
                that.questionsList.push({
                  totalMark: '',
                  type: item.type,
                  questionList: [
                    item,
                  ]
                })
              } else {
                var have = that.questionsList.some((ele, index1) => {
                  if (ele.type == item.type) {
                    return true;
                  }
                })
                if (have) {
                  that.questionsList.forEach((ele, index1) => {
                    if (ele.type == item.type) {
                      that.questionsList[index1].questionList.push(item);

                    }

                  })
                } else {
                  that.questionsList.push({
                    totalMark: '',
                    type: item.type,
                    questionList: [
                      item
                    ]
                  })
                }
              }
            })
            that.questionsList.forEach((ele, index) => {
              var totalScore = 0;
              ele.questionList.forEach(item => {
                totalScore += item.score;
              })
              ele.totalMark = totalScore;
            })
          } else {
            that.questionsList = that.cacheQuestionsList;
          }
          console.log(that.questionsList, 'that.questionsList')
          // 解决填空题每空绑定的值
          this.completionFn(that.questionsList)


        });
    },
    // 填空题数据处理
    completionFn(questionsList) {
      console.log(questionsList, 'questionsListquestionsList');
      let stListNum = 0; // 有多少题
      questionsList.forEach(item => {
        if (+item.type === 6) {
          console.log(item, '66666');
          item.questionList.forEach(compleItem => {
            if (!compleItem.completionAnswer) {
              this.$set(compleItem, 'completionAnswer', []); // 使用 this
              for (let i = 0; i < compleItem.completeInputNum; i++) {
                compleItem.completionAnswer.push({ answer: '' });
              }
            }
            console.log(compleItem, 'compleItem');
          });
        }

        item.questionList.forEach(compleItem => {
          if (compleItem.type != 7) stListNum += 1;
          if (compleItem.userAnswer) {
            compleItem.result = true;
            if (+compleItem.type !== 6) {
              console.log(compleItem, 'compleItem');
              console.log(compleItem.userAnswer, 'compleItem.userAnsweruserAnsweruserAnsweruserAnswer');
              this.$set(compleItem, 'myAnswers', compleItem.userAnswer || ''); // 使用 this
              (compleItem.options && compleItem.options.length) && compleItem.options.forEach(options => {
                if (compleItem.type == 2) { // 多选题
                  if (compleItem.userAnswer.indexOf(options.optionNumber) !== -1) {
                    this.$set(options, 'isAnswers', true); // 使用 this
                  } else {
                    this.$set(options, 'isAnswers', false); // 使用 this
                  }

                } else {
                  if (compleItem.userAnswer === options.optionNumber) {
                    this.$set(options, 'isAnswers', true); // 使用 this
                  } else {
                    this.$set(options, 'isAnswers', false); // 使用 this
                  }
                }

              });
            }
            if (+compleItem.type === 6) {
              this.$set(compleItem, 'completionAnswer', []); // 使用 this
              const userAnswer = compleItem.userAnswer.split(',');
              console.log(userAnswer, 'userAnswer');
              userAnswer.forEach(item1 => {
                compleItem.completionAnswer.push({ answer: item1 });
              });
            }
          }
        });

        // 组合题下的填空题
        if (item.type == 7) {
          console.log(item, 'item-777777');
          item.questionList.forEach(val => {
            val.subDtoList.forEach(question => {
              stListNum += 1;
              if (question.type == 6) {
                if (!question.completionAnswer) {
                  this.$set(question, 'completionAnswer', []); // 使用 this
                  for (let i = 0; i < question.completeInputNum; i++) {
                    question.completionAnswer.push({ answer: '' });
                  }
                }
              } else {
                console.log('组合体下的其他题型')
                val.subDtoList.forEach(compleItem => {
                  if (compleItem.type != 7) stListNum += 1;
                  if (compleItem.userAnswer) {
                    compleItem.result = true;
                    if (+compleItem.type !== 6) {
                      console.log(compleItem, 'compleItem');
                      console.log(compleItem.userAnswer, 'compleItem.userAnsweruserAnsweruserAnsweruserAnswer');
                      this.$set(compleItem, 'myAnswers', compleItem.userAnswer || ''); // 使用 this
                      (compleItem.optionList && compleItem.optionList.length) && compleItem.optionList.forEach(options => {
                        if (compleItem.type == 2) { // 多选题
                          if (compleItem.userAnswer.indexOf(options.optionNumber) !== -1) {
                            this.$set(options, 'isAnswers', true); // 使用 this
                          } else {
                            this.$set(options, 'isAnswers', false); // 使用 this
                          }
                        } else {
                        if (compleItem.userAnswer === options.optionNumber) {
                          this.$set(options, 'isAnswers', true); // 使用 this
                        } else {
                          this.$set(options, 'isAnswers', false); // 使用 this
                        }
                      }
                      });
                    }
                    if (+compleItem.type === 6) {
                      this.$set(compleItem, 'completionAnswer', []); // 使用 this
                      const userAnswer = compleItem.userAnswer.split(',');
                      console.log(userAnswer, 'userAnswer');
                      userAnswer.forEach(item1 => {
                        compleItem.completionAnswer.push({ answer: item1 });
                      });
                    }
                  }
                });
              }



            });
          });
        }
        console.log(item, 'item-zi');
      });
      this.stList = stListNum; // 使用 this
      console.log(this.questionsList, 'this.questionsList=====', stListNum);
    },
    // completionFn(questionsList,that){
    //     console.log(questionsList,'questionsListquestionsList');
    //     let stListNum = 0; // 有多少题
    //     questionsList.forEach(item => {
    //         if(+item.type === 6){
    //             console.log(item,'66666');
    //             item.questionList.forEach(compleItem => {
    //                 if(!compleItem.completionAnswer){
    //                     that.$set(compleItem,'completionAnswer',[])
    //                     for(let i = 0; i < compleItem.completeInputNum ; i++){
    //                             compleItem.completionAnswer.push({ answer:''})
    //                     } 
    //                 }
    //                 console.log(compleItem,'compleItem');
    //             })
    //         }
    //         // 之前写的代码，不清楚具体逻辑

    //         item.questionList.forEach(compleItem => {
    //             if(compleItem.type!=7) stListNum+=1
    //             if(compleItem.userAnswer){
    //                 compleItem.result = true;
    //                 if(+compleItem.type !== 6){
    //                   console.log(compleItem,'compleItem')
    //                   console.log(compleItem.userAnswer,'compleItem.userAnsweruserAnsweruserAnsweruserAnswer')

    //                   that.$set(compleItem,'myAnswers',compleItem.userAnswer||'')
    //                     (compleItem.options && compleItem.options.length) && compleItem.options.forEach(options => {
    //                         if(compleItem.userAnswer === options.optionNumber){
    //                           that.$set(options,'isAnswers',true)
    //                         }else {
    //                           that.$set(options,'isAnswers',false)
    //                         }
    //                     })
    //                 }
    //                 if(+compleItem.type === 6){
    //                   that.$set(compleItem,'completionAnswer',[])
    //                     const userAnswer = compleItem.userAnswer.split(',');
    //                     console.log(userAnswer,'userAnswer')
    //                     userAnswer.forEach(item1 => {
    //                         compleItem.completionAnswer.push({answer:item1})
    //                     })
    //                 }
    //             }
    //         })
    //         // 组合题下的填空题
    //         if(item.type==7){
    //             console.log(item,'item-777777');
    //             item.questionList.forEach(val=>{
    //                 val.subDtoList.forEach(question=>{
    //                     stListNum+=1
    //                     if(question.type==6){
    //                         if(!question.completionAnswer){
    //                           that.$set(question,'completionAnswer',[])
    //                             for(let i = 0; i < question.completeInputNum ; i++){
    //                                 question.completionAnswer.push({ answer:''})
    //                             } 
    //                         }
    //                     }
    //                 })
    //             })
    //         }
    //         console.log(item,'item-zi');
    //     })
    //     this.stList = stListNum
    //     console.log(this.questionsList,'this.questionsList=====',stListNum);
    // },
    // 试卷提交数据处理
    answerListFn(data, combinationFlag = false, questionType) {
      const list = combinationFlag ? data.subDtoList : data
      list.forEach(question => {
        let myAnswers = [];
        if (question.type == 6) {
          let num = 0;
          question.completionAnswer.forEach(completionItem => {
            if (completionItem.answer !== "") num++;
            myAnswers.push(completionItem.answer)
          })
          if (num) question.myAnswers = myAnswers.join(',')
        }
        console.log('question----->zizizizi', question)
        if ((question.myAnswers && question.myAnswers.length > 1) && question.type !== 6) {
          let userAnswer = question.myAnswers.split(",");
          question.myAnswers = Array.from(userAnswer).sort().join(",");
        }
        const questionId = combinationFlag ? question.parentId : question.questionId
        const subQuestionType = combinationFlag ? question.type : null
        let param = {
          answer: question.myAnswers,
          mark: question.mark ? 1 : 0,
          questionId: questionId,
          questionType: questionType,
          uri: question.uri ? question.uri : '',
          subQuestionId: question.id ?? null, // 当前选项所在题id
          subQuestionType,
        };
        if (question.myAnswers !== undefined || question.mark === false) {
          this.saveData.answerList.push(param);
          console.log('this.saveData.answerList--->zizizizi', this.saveData.answerList)
        }
        if (question.myAnswers == undefined) {
          param.answer = ''
          this.saveData.answerList.push(param);
          console.log('this.saveData.answerList--->zizizizi-222', this.saveData.answerList)
        }
      })
    },
    // 试卷信息
    getPaperList() {
      this.saveData.commitTime = this.$dateFormat.dateFormat();
      this.saveData.userPaperId = this.userPaperId;
      if (this.paperType == 1 || this.paperType == 3 || this.paperType == 4) {
        this.saveData.answerList = [];
        this.questionsList.forEach(item => {
          console.log('questionsList----->', this.questionsList)
          const item1 = item;
          if (item1.type == 7) {
            item1.questionList.forEach(subQuestion => {
              this.answerListFn(subQuestion, true, item1.type)
            })
          } else {
            this.answerListFn(item1.questionList, false, item1.type)
          }

        });
      }
    },

    // 判断人脸验证
    uploadTestPaper(type) {
      if ((this.isFaceRecogCommitExam == 1 && !this.verificationTime) || (this.isFaceRecogCommitExam == 1 && this.verificationTime > 0)) {
        this.submitOrNot = true;
        this.commitExam = 'exam';
        this.examFaceType = 3;
        this.verification = true;
        this.confirmdialog.show = true;
        this.verificationAddress = {};
        this.verificationFailAddress = {};
        this.verificatioPpauseAddress = {};
        this.verificatioInvalidAddress = this.returnPath;
        clearInterval(this.timer);
      } else {
        this.commitAllPaper(type)
      }
    },
    commitPaperByTimes() {
      // 获取答过的题目数据
      this.getPaperList();
      console.log('this.saveData.answerList.length----->', this.saveData)
      console.log('this.saveData.answerList.length----->', this.saveData.answerList.length)
      // 把answerList的answer为''的数据的answer置为null'
      // this.saveData.answerList.forEach(item=>{
      //   if(item.answer==''){
      //     item.answer=null
      //   }
      // })
      if (this.saveData.answerList.length > 0) {
        this.$api.exam.commitPaper(this.saveData).then(data => {
          if (!data.success) {
            return
          }
        })
      }


    },
    // 提交试卷
    commitAllPaper(type, status) {
      if (this.submitFlag == true) {
        this.submitFlag = false
        clearInterval(this.warning);
        this.getPaperList();
        this.saveData.invalidType = type;
        console.log('this.saveData----->666', this.saveData, type)
        this.$api.exam.commitAllPaper(this.saveData).then(data => {
          window.onresize = null;
          this.userSwitchScreen = null;
          this.submitFlag = true;
          this.offlineCommit = false;
          // 判断是否点击了退出
          if (sessionStorage.getItem('expect') == 'yes') {
            Vue.prototype.resetSetItem('signOutStorage', 'signOut');
          } else {
            // this.$router.replace({
            //   path:'/examDetails',
            //   query:{
            //       bussinessId: this.businessId,
            //       examId: this.examId,
            //       taskType: this.taskType,
            //       type:this.taskType,
            //       dymode: this.$route.query.dymode,
            //       title: sessionStorage.getItem("examDetailsTitle"),
            //       timeSlot: sessionStorage.getItem("examDetailsTimeSlot"),
            //       startTime:sessionStorage.getItem("examDetailStartTime"),
            //       endTime:sessionStorage.getItem("examDetailendTime")
            //   }
            // })
            if (!data.success) {
              return
            }
            this.$message.success('考卷提交成功')
            this.$router.replace({
              path: '/exam/handPaper',
              query: {
                userPaperId: this.userPaperId,
                businessId: this.businessId,
                examId: this.examId,
                type: 'test',
                taskType: this.taskType,
                dymode: this.$route.query.dymode,
                source: this.source
              }
            })
          }
          // 交卷后关闭直播
          if (this.$refs.live) {
            this.$refs.live.stopLiving();
          }
        }).catch(error => {
          this.submitFlag = true;
          this.offlineCommit = true;
          this.commitType = type;
          this.commitStatus = status;
        });
      }
    },
    // 交卷
    submitExam() {
      // 允许交卷时长
      if (this.allowCommitDurationExam > 0) {
        // 考试允许用时
        if (this.secondsExam > 0) {
          // 未超过允许交卷时长
          if (this.secondsExam - this.verificationTime < this.allowCommitDurationExam * 60) {
            this.timeUsed = Math.floor((this.secondsExam - this.verificationTime) / 60);
            this.timeRemain = this.allowCommitDurationExam - this.timeUsed;
            this.timeLimitdialogVisible = true;
            this.setallowtime()
          } else {
            this.goSubmit();
          }
          // 动态时间
        } else {
          this.clickSubmitTime = new Date().getTime();
          // 未超过允许交卷时长
          if ((this.clickSubmitTime - this.startAnswerTime) / 1000 < this.allowCommitDurationExam * 60) {
            this.timeUsed = Math.floor((this.clickSubmitTime - this.startAnswerTime) / 60000);
            this.timeRemain = this.allowCommitDurationExam - this.timeUsed;
            this.timeLimitdialogVisible = true;
            this.setallowtime();
          } else {
            this.goSubmit();
          }
        }
      } else {
        this.goSubmit();
      }
    },
    goSubmit() {
      this.onlyCertain = true;
      let num = 0;
      if (this.paperType == 1 || this.paperType == 3 || this.paperType == 4) {
        this.questionsList.forEach(val => {
          val.questionList.forEach(val2 => {
            if (val2.type == 7) {
              val2.subDtoList.forEach(item => {
                if (item.result) num += 1;
              })
            } else {
              if (val2.result) num += 1;
            }
          });
        });
        console.log('========存在人脸识别========', num, Number(this.stList), num === Number(this.stList));
        if (num === Number(this.stList)) {
          // 存在人脸识别
          if (this.isFaceRecogCommitExam == 1) {

            this.submitdialogVisible = true;
          } else {
            this.coursedialogVisible = true;
            this.text1 = '提示';
            this.text2 = '提交后不能撤回';
            this.cancelText = '继续答题';
            this.submitText = '交卷';

          }
        } else {
          this.coursedialogVisible = true;
          this.remainingNum = Number(this.stList) - num;
          this.text1 = '提示';
          this.text2 = '你还有题目未作答，确认交卷？';
          this.cancelText = '继续答题';
          this.submitText = '交卷';
          // 题目未答完时判断是否需要人脸识别弹框
          if (this.isFaceRecogCommitExam == 1) {
            this.distinguish = 1
          }
        }
      } else if (this.paperType == 2) {
        if (this.isFaceRecogCommitExam == 1) {
          this.submitdialogVisible = true;
        } else {
          this.coursedialogVisible = true;
          this.text1 = '提示';
          this.text2 = '提交后不能撤回';
          this.cancelText = '继续答题';
          this.submitText = '交卷';
        }
      }
    },
    setallowtime() {
      this.allowtimeInterval = setInterval(() => {
        this.allowtime = this.allowtime - 1
        if (this.allowtime == 1) {
          this.timeLimitdialogVisible = false;
          clearInterval(this.allowtimeInterval)
        }
      }, 1000)
      this.allowtime = 5
    },
    // 弹框三秒倒计时
    setFrameTime(num) {
      this.frameTime = num;
      this.knowTime = setInterval(() => {
        num -= 1;
        this.frameTime = num;
        this.submitText = '知道了' + " (" + this.frameTime + "S" + '后关闭' + ")";
      }, 1000)
    },
    // 考试倒计时
    countDown(maxtime) {
      // maxtime = 60
      // 定义函数 此函数名必须与触发事件的函数名一致
      console.log('五分钟一分钟时间限制', maxtime)
      this.timer = setInterval(() => {
        if (maxtime > -1) {
          // 时间过半时进行人脸验证
          if (this.halfTime == maxtime && this.flag && this.isFaceRecogDuringExam == 1) {

            this.verification = true;
            this.confirmdialog.show = true;
            this.examFaceType = 2;
            this.verificationAddress = {};
            this.verificationFailAddress = {};
            this.verificatioPpauseAddress = {};
            this.verificatioInvalidAddress = this.returnPath;
          }
          // 缓存当前时间
          this.verificationTime = maxtime;
          this.duration = this.timeFormat(maxtime);
          maxtime -= 1;
          this.remainingTime = maxtime;
          // 剩余5分钟
          if (this.duration == '00:05:01') {
            this.fiveTime = 5;
            this.coursedialogVisible = true;
            let audioText = '距离考试结束：剩余5分钟';
            this.audioplay(audioText);
            this.text1 = '提示';
            this.text2 = '距离考试结束仅剩5分钟';
            this.submitText = '知道了 (3S后关闭)';
            this.setFrameTime(3);
            this.onlyCertain = false;
            setTimeout(() => {
              clearInterval(this.knowTime);
              this.coursedialogVisible = false;
              if (this.clickFlagFive) {
                this.clickFlagFive = false;
              }
              this.fiveTime = null;
            }, 3000);
            // 剩余一分钟
          } else if (this.duration == '00:01:01') {
            this.oneTime = 1;
            this.coursedialogVisible = true;
            let audioText = '距离考试结束：剩余1分钟';
            this.audioplay(audioText);
            this.text1 = '提示';
            this.text2 = '距离考试结束仅剩1分钟';
            this.submitText = '知道了 (3S后关闭)';
            this.setFrameTime(3);
            this.onlyCertain = false;
            setTimeout(() => {
              this.oneTime = null;
              clearInterval(this.knowTime);
              this.coursedialogVisible = false;
              if (this.clickFlagOne) {
                this.clickFlagOne = false;
              }
              this.oneTime = null;
            }, 3000);
          }
          // 考试时间结束
        } else {
          clearInterval(this.timer);
          this.voluntarilydialogVisible = true;
          // console.log('打开了系统交卷弹窗')
          this.text1 = '警告';
          this.text2 = '考试时间到，系统将强制交卷！';
          this.submitText = '知道了 (3S后关闭)';
          this.setFrameTime(3);
          this.onlyCertain = false;
          setTimeout(() => {
            clearInterval(this.knowTime);
            this.voluntarilydialogVisible = false;
            this.uploadTestPaper(9);
          }, 3000);
        }
      }, 1000);
    },
    // 时间格式转换
    timeFormat(time) {
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor((time / 60) % 60);
      const seconds = Math.floor(time % 60);
      const hours1 = hours < 10 ? `0${hours}` : hours;
      const minutes1 = minutes < 10 ? `0${minutes}` : minutes;
      const seconds1 = seconds < 10 ? `0${seconds}` : seconds;
      return `${hours1}:${minutes1}:${seconds1}`
    },
    // 语音播报
    audioplay(text) {
      var ttsDiv = document.getElementById('examTaking');
      var au1 = '<audio id="tts_autio_id" autoplay="autoplay">';
      var sss = '<source id="tts_source_id" src="http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=5&text=' + text + '" type="audio/mpeg">';
      var eee = '<embed id="tts_embed_id" height="0" width="0" src="">';
      var au2 = '</audio>';
      ttsDiv.innerHTML = au1 + sss + eee + au2;
    },
    // 管理员强制结束考试
    handleOverFace() {
      let monitorType = this.loopErrData.monitorType;
      this.commitAllPaper(monitorType);
      if (this.$refs.live) {
        this.$refs.live.stopLiving();
      }
    },
    // 获取考试信息 判断考试是否直播
    async examDetailData() {
      let params = {
        id: this.$route.query.examId
      }
      let res = await this.$api.exam.examDetailData(params);
      if (!res.success) return;
      this.controlSwitch = res.data;
    },
    // 轮询错误请求
    getDateLoop(timeout = 10000) {
      this.polling = setInterval(() => {
        let params = {
          userPaperId: this.$route.query.userPaperId
        }
        this.$api.exam.queryMsgInform(params).then((res) => {
          if (res.success) {
            let loopErrData = res.data.msgInformVo;
            let exceptionRecord = res.data.recordVo;
            // 强制交卷
            if (loopErrData.monitorType != null && loopErrData.monitorType == 7) { loopErrData.forceCommit = true }
            this.$refs.live.monitorType = loopErrData.monitorType == null ? '' : loopErrData.monitorType;
            //异常记录
            this.$refs.live.exceptionRecord = exceptionRecord;
            // this.$refs.warn.exceptionRecord = exceptionRecord;
            // 异常弹框信息
            if (loopErrData.monitorType != 1 && loopErrData.monitorType != 9 && loopErrData.monitorType != 10 && loopErrData.monitorType != null) {
              this.loopErrData = loopErrData;
              let msg = ''
              switch (this.loopErrData.monitorType) {
                case 3:
                  msg = '检测到多个人脸，请遵守考场纪律，确保本人单独考试'
                  break;
                case 4:
                  msg = '检测到人脸与身份证不符，请遵守考场纪律，确保本人单独考试'
                  break;
                case 5:
                  msg = '未检测到人脸，请遵守考场纪律，考试中途不要离场'
                  break;
                default:
              }
              if (msg) {
                resetMessage({
                  showClose: true,
                  message: msg,
                  type: 'error'
                })
              }

            }
            // 获取手机端推流状态
            this.phoneLiveStatus = res.data.linkStatusVo.phoneLiveStatus;
            // 调用弹窗
            this.disposeShowDialog(loopErrData)
          }
        })
      }, timeout)
    },
    // 处理异常情况弹框
    disposeShowDialog(data) {
      // pc检测推流状态
      let quality = localStorage.getItem('uplinkNetworkQuality');
      // pc端推流异常
      if (quality == 5 || quality == 6) {
        // 异常遮罩
        this.showPcShade = true;
        //显示设备检测按钮
        this.showDetectionBtn = true;
        // pc推流异常上报
        this.linkLiveStatus(0);
      } else {
        // pc推流正常上报
        this.linkLiveStatus(1);
        //隐藏设备检测按钮
        this.showDetectionBtn = false;
      }
      // h5端推流异常
      if (!this.phoneLiveStatus && this.controlSwitch.isOpenPhoneCamera == 1) {
        // 显示h5遮罩
        this.showPcShade = true;
        // 异常二维码
        this.isShowH5Error = true;
        // 生成二维码
        this.createCodeH5URL(true);
      } else {
        // 隐藏h5遮罩
        this.showPcShade = false;
        // 隐藏二维码
        this.isShowH5Error = false;
        // 清空二维码
        this.urlH5Str = '';
      }
      // 异常情况弹框
      if (data.monitorType == null) return;
      // if (data.monitorType != 1 && data.monitorType != 9 && data.monitorType != 10 && data.monitorType != 2) {
      //   this.faceWarningVisible = true;
      // }
      // 强制交卷，后台警告打开弹窗
      if (data.monitorType == 6 || data.monitorType == 7) {
        this.faceWarningVisible = true;
        localStorage.setItem('faceWarningVisible', this.faceWarningVisible);
        localStorage.setItem('loopErrData', JSON.stringify(data))
      }
    },
    // 上报pc推流是否正常
    linkLiveStatus(status) {
      let params = {
        channel: 1,
        status: status,
        userPaperId: this.$route.query.userPaperId
      }
      this.$api.exam.linkLiveStatus(params).then((res) => { })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../asset/css/testPaper.scss';

.combination {
  padding-left: 54px;
}
</style>
